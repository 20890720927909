@import '../../General/Styles/mixin.scss';
@import '../../General/Styles/variables';

.body {
    width: 100%;
    @include flexStyles(flex, row, center, space-between);
    position: fixed;
    top: 0;
    padding: 15px;
    background-color: $main_color;
    z-index: 1;
}

.logo {
    @include flexStyles(flex, row, flex-end, center);
    gap: 5px;
    padding: 5px;
    cursor: pointer;
}

.bigText {
    @include textStyles("Inter", 24px, 600, normal, $white);
    box-sizing: content-box;
}

.smallText {
    @include textStyles("fantasy", 16px, 200, normal, $white);
    padding-bottom: 1px;
    letter-spacing: 0.3px;
}

.title{
    flex: 1;
    @include textStyles("", 26px, 200, normal, $white);
    text-align: center;
}

.infoBox{
    margin-right: 50px;
    @include flexStyles(flex,row,center,center);
    gap: 40px;
}

//media

@media screen and (max-width:1210px) {
    .title{
font-size: 22px;
    }
  }