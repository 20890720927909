$main_color:rgba(24, 55, 79);
$page_background:rgba(132,180,216,.2);
$light_bg: #fcfcfd;
$white:#fff;
$errorColor:  #b00020;
$text_dark:#344054;
$input_bg:#fff7f7;
$formInput:#ffffff;
$disable-button: var(--Green, #a3aed0);
$primary_button:#1BA6DC;
$dark_grey: #707eae;
$light_gray:#c1c2c4;
$no-data-title: #1f1f39;
$no-data-description: #b8b8d2;
$table_header:rgb(196, 222, 242);
$table_item:rgb(247, 247, 247);
$buttun_darkBlue:#006af5;
$avatar:#70b7f8;