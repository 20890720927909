@import '../../General/Styles/mixin';
@import '../../General/Styles/variables';

.container {
    border: 1px solid green;
    width: inherit !important;
    overflow: auto !important;
    flex: 1 !important;
}

.cellHeader {
    @include flexStyles(flex, row, center, center);
    text-wrap: nowrap;
    padding: 10px 21px;
    background-color: $table_header;
    border-right: 1px solid $dark_grey;
    background-color: $table_header;
}

.cellBody {
    @include flexStyles(flex, row, center, center);
    text-wrap: nowrap;
     padding: 5px 5px 10px;
    border-right: 1px solid $dark_grey;
    background-color: $table_item;
}

.itemIcon {
    width: 70px;
    @include flexStyles(flex, row, center, center);
    text-wrap: nowrap;
    border-right: 1px solid $dark_grey;
}

.itemText {
    min-width: 200px;
    flex: 1;
}

.itemNumber {
    width: 200px;
}

.itemSelect {
    min-width: 21vw;
    flex: 1
}

.plusFooter {
    @include flexStyles(flex, row, center, normal);
    gap: 15px;
    color: $buttun_darkBlue;
    width: fit-content;
    font-size: 18px;
    cursor: pointer;
}

//media

@media screen and (max-width:1210px) {
    .itemText {
        min-width: 120px;
        max-width: 120px;
        flex: 1;
    }
  }

@media screen and (max-width:1100px) {
    .itemSelect {
        min-width: 310px;
    }
  }

  @media screen and (max-width:1000px) {
    .itemSelect {
        min-width: max-content;
        flex: 1
    }
  }